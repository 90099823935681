import i18next, { t } from "i18next";
import React from "react";
import imagee3 from "../../../src/assets/shapes/22.jpg";
import imagee4 from "../../../src/assets/shapes/12.jpg";
// import imagee3 from "../../../src/assets/shapes/خضرات وفواكه .jpg";
export default class AboutFive extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <section className="about-one" id="about">
          {/* <div className="shape1 float-bob-y">
            <img
              src={publicUrl + "assets/images/shapes/about-v1-shape1.png"}
              alt="#"
            />
          </div>
          <div className="shape2 float-bob-y">
            <img
              src={publicUrl + "assets/images/shapes/about-v1-shape2.png"}
              alt="#"
            />
          </div> */}
          {/* <div className="shape3"></div> */}

          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-6">
                {i18next.language === "ar" ? (
                  <div className="w-100 ms-5">
                    <img className="w-100" src={imagee3} alt="about" />

                    {/*   <div className="about-one__bg-content">
                      <div className="img-box">
                        <div className="inner">
                          <img className="w-100" src={imagee2} alt="#" />
                        </div>
                      </div>
                    </div> */}
                  </div>
                ) : (
                  <div className="w-100 me-5">
                    <img className="w-100" src={imagee4} alt="about" />

                    {/*   <div className="about-one__bg-content">
                      <div className="img-box">
                        <div className="inner">
                          <img className="w-100" src={imagee2} alt="#" />
                        </div>
                      </div>
                    </div> */}
                  </div>
                )}
              </div>
              {/* Start About One Content */}
              <div className="col-xl-6">
                <div className="about-one__content w-75">
                  <div className="sec-title">
                    <div className="sec-title__tagline">
                      {/* <h6>{t("aboutUs")}</h6> */}
                      {/* <span className="right"></span> */}
                    </div>
                    <h2 className="sec-title__title">
                      {t("aboutusparagraphpage")}
                    </h2>
                  </div>
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4">
                      <div className="about-one__content-text2-single">
                        <h3>
                          {" "}
                          <span className="icon-confirmation"></span>{" "}
                          {t("Our motto")}
                        </h3>
                        <p>{t("OurMottoParag")}</p>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4">
                      <div className="about-one__content-text2-single">
                        <h3>
                          {" "}
                          <span className="icon-confirmation"></span>
                          {t("Our goal")}
                        </h3>
                        <p>{t("OurGoalParag")}</p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4">
                      <div className="about-one__content-text2-single">
                        <h3>
                          {" "}
                          <span className="icon-confirmation"></span>
                          {t("Our vision")}
                        </h3>
                        <p>{t("OurVisionParag")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="about-one__content-text2-single">
                        <h3>
                          {" "}
                          <span className="icon-confirmation"></span>{" "}
                          {t("abouttitle")}
                        </h3>
                        <p>{t("abouttitle2")}</p>
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="about-one__content-text2-single">
                        <h3>
                          {" "}
                          <span className="icon-confirmation"></span>
                          {t("abouttitle3")}
                        </h3>
                        <p>{t("abouttitle4")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="about-one__content-text1">
                    <p className="text2 fs-6 lh-lg">-{t("aboutparagraph22")}</p>
                    <p className="text2 fs-6 lh-lg">-{t("aboutparagraph23")}</p>
                    <p className="text2 fs-6 lh-lg">-{t("Safe packing")}</p>
                    <p className="text2 fs-6 lh-lg">-{t("Fast delivery")}</p>
                  </div>
                  <div className="about-one__content-text1">
                    <p className="text2 fs-6 lh-lg">{t("aboutparagraphsec")}</p>
                  </div>
                  <div className="about-one__content-text1">
                    <p className="text2 fs-6 lh-lg">
                      {t("aboutparagraphsec2")}
                    </p>
                  </div>

                  {/* <div className="about-one__content-text2">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="about-one__content-text2-single">
                          <h3>
                            {" "}
                            <span className="icon-confirmation"></span>{" "}
                            {t("abouttitle")}
                          </h3>
                          <p>{t("abouttitle2")}</p>
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="about-one__content-text2-single">
                          <h3>
                            {" "}
                            <span className="icon-confirmation"></span>
                            {t("abouttitle3")}
                          </h3>
                          <p>{t("abouttitle4")}</p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="about-one__content-bottom">
                    <div className="btn-box">
                      <a className="thm-btn" href="about">
                        <span className="txt">{t("discoverMore")}</span>
                        <i className="fa fa-angle-double-right"></i>
                      </a>
                    </div>

                    
                  </div> */}
                </div>
              </div>
              {/* End About One Content */}
            </div>
          </div>
        </section>
      </>
    );
  }
}

// <div className="about-one__content">
// <div className="sec-title">
//   <div className="sec-title__tagline">
//     <h6>{t("aboutUs")}</h6>
//     <span className="right"></span>
//   </div>
//   <h2 className="sec-title__title">
//     {t("aboutusparagraph")}
//   </h2>
// </div>

// <div className="about-one__content-text1">
//   <p className="text1">{t("aboutparagraph2")}</p>
//   <p className="text2">{t("aboutparagraph3")}</p>
// </div>

// <div className="about-one__content-text2">
//   <div className="row">
//     <div className="col-xl-4 col-lg-4 col-md-4">
//       <div className="about-one__content-text2-single">
//         <h3>
//           {" "}
//           <span className="icon-confirmation"></span>{" "}
//           {t("Our motto")}
//         </h3>
//         <p>{t("OurMottoParag")}</p>
//       </div>
//     </div>

//     <div className="col-xl-4 col-lg-4 col-md-4">
//       <div className="about-one__content-text2-single">
//         <h3>
//           {" "}
//           <span className="icon-confirmation"></span>
//           {t("Our goal")}
//         </h3>
//         <p>{t("OurGoalParag")}</p>
//       </div>
//     </div>
//     <div className="col-xl-4 col-lg-4 col-md-4">
//       <div className="about-one__content-text2-single">
//         <h3>
//           {" "}
//           <span className="icon-confirmation"></span>
//           {t("Our vision")}
//         </h3>
//         <p>{t("OurVisionParag")}</p>
//       </div>
//     </div>
//   </div>
//   <div className="row">
//     <div className="col-xl-6 col-lg-6 col-md-6">
//       <div className="about-one__content-text2-single">
//         <h3>
//           {" "}
//           <span className="icon-confirmation"></span>{" "}
//           {t("abouttitle")}
//         </h3>
//         <p>{t("abouttitle2")}</p>
//       </div>
//     </div>

//     <div className="col-xl-6 col-lg-6 col-md-6">
//       <div className="about-one__content-text2-single">
//         <h3>
//           {" "}
//           <span className="icon-confirmation"></span>
//           {t("abouttitle3")}
//         </h3>
//         <p>{t("abouttitle4")}</p>
//       </div>
//     </div>
//   </div>
// </div>

{
  /* <div className="about-one__content-bottom">
  <div className="btn-box">
    <a className="thm-btn" href="about">
      <span className="txt">{t("discoverMore")}</span>
      <i className="fa fa-angle-double-right"></i>
    </a>
  </div>

  
</div> */
}
{
  /* </div> */
}
