import { changeLanguage, t } from "i18next";
import React from "react";
import { TbWorld } from "react-icons/tb";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { FiAlignJustify } from "react-icons/fi";

const Nav = () => {
  return (
    <>
      <ul className="main-menu__list">
        <li className="dropdown current">
          <Link to={process.env.PUBLIC_URL + `/`}>
            {t("Home")} 
          </Link>
          {/* <ul>
                    <li><Link to={process.env.PUBLIC_URL + `/`}>Home One</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/home-two`}>Home Two</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/home-three`}>Home Three</Link></li>
                    <li className="dropdown">
                        <Link to={process.env.PUBLIC_URL + `#`}>Header Styles</Link>
                        <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/`}>Header One</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-two`}>Header Two</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-three`}>Header Three</Link></li>
                        </ul>
                    </li>
                </ul> */}
        </li>

        <li>
          {/* <Link to={process.env.PUBLIC_URL + `/AboutOne`}>
            {t("About")} <span className="line"></span>
          </Link> */}
          <NavHashLink
          smooth 
            to="/#about"
            activeClassName="selected"
            activeStyle={{ color: "red" }}
            // etc...
          >
         {t("About")}
          </NavHashLink>
        </li>

        <li>
          {/* <Link to={process.env.PUBLIC_URL + `/ServiceOne`}>
            {t("Services")} <span className="line"></span>
          </Link> */}
          <NavHashLink
          smooth 
            to="/#product"
            activeClassName="selected"
            activeStyle={{ color: "red" }}
            // etc...
          >
         {t("product")}
          </NavHashLink>
          {/* <ul>
                    <li><Link to={process.env.PUBLIC_URL + `/services`}>Services</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/arbor-management`}>Arbor Management</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/garden-management`}>Garden Management</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/nursery`}>Nursery & Tree Farm</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/trimming`}>Trimming & Pruning</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/weeds-control`}>Pests & Weeds Control</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/flowers-garden`}>Fruits & Flowers Garden</Link></li>
                </ul> */}
        </li>
        {/* <li>
        <Link to={process.env.PUBLIC_URL + `/ProjectOne`}>
            {t("product")} <span className="line"></span>
          </Link>
          <NavHashLink
          smooth 
            to="/#contact"
            activeClassName="selected"
            activeStyle={{ color: "red" }}
            // etc...
          >
          {t("product")}
          </NavHashLink>
        </li> */}

 {/* <li className="dropdown">
                <Link to={process.env.PUBLIC_URL + `#`}>Pages <span className="line"></span></Link>
                <ul>
                    <li><Link to={process.env.PUBLIC_URL + `/team`}>Team</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/team-details`}>Team Details</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/portfolio-1`}>Portfolio 01</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/portfolio-2`}>Portfolio 02</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/faq`}>Faq</Link></li>
                </ul>
            </li>  */}

        {/* <li className="dropdown">
                <Link to={process.env.PUBLIC_URL + `#`}>Blog <span className="line"></span></Link>
                <ul>
                    <li><Link to={process.env.PUBLIC_URL + `/blog`}>Blog</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/blog-grid`}>Blog Grid</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/blog-details`}>Blog Details</Link></li>
                </ul>
            </li> */}
        <li>
          {/* <Link href={process.env.PUBLIC_URL + `/ContactOne`}>
            {t("Contact")} <span className="line"></span>
          </Link> */}
          <NavHashLink
          smooth 
            to="/#contact"
            activeClassName="selected"
            activeStyle={{ color: "red" }}
            // etc...
          >
          {t("Contact")} 
          </NavHashLink>
        </li>

        <div className="btn-group ">
          <button
            type="button"
            className="btn btn-transparent border-0 "
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
        <FiAlignJustify color="#0b3d2c" fontSize="2em"  />    
          </button>
          <ul className="dropdown-menu">
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => changeLanguage("en")}
              >
              {t('language2')}  
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => changeLanguage("fr")}
              >
                {t('language3')}
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => changeLanguage("ar")}
              >
              {t('language1')}
              </a>
            </li>
          </ul>
        </div>
      </ul>
    </>
  );
};

export default Nav;
