import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import imaglogo from "../../../src/assets/shapes/logog.png";
import { t } from "i18next";
import { HashLink, NavHashLink } from "react-router-hash-link";
import axios from "axios";
import { CategoryContext } from "../context/categorycontext";
import Slider from "react-slick";

export default function Product() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { data, loading, error } = useContext(CategoryContext);
  const { langproduct } = useContext(CategoryContext);
  console.log("langproduct", langproduct);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: data?.length|| 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow:data?.length|| 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow:  3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 5,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    rtl: true,
  };

  return (
    <>
      <section className="services-one" id="product">
        <div className="gradient-bg"></div>
        <div className="auto-container">
          <div className="sec-title text-center">
            <div className="sec-title__tagline">
              <span className="left"></span>
              <h6>{t("ourproduct")}</h6>
              <span className="right"></span>
            </div>
            <h2 className="sec-title__title">{t("ProfessionalFeatured")}</h2>
          </div>

          <div className="row"   >
            <Slider {...settings}>
              {data?.map((item, index) => {
                return (
                  <div
                    className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft "
                    data-wow-delay="0ms"
                    data-wow-duration="1000ms"
                    key={index}
                  >
                    <div className="services-one__single heightproduct ">
                      <div className="shape1">
                        <img
                          src={
                            publicUrl +
                            "assets/images/shapes/services-v1-shape2.png"
                          }
                          alt="#"
                        />
                      </div>
                      <div className="shape2">
                        <img
                          src={
                            publicUrl +
                            "assets/images/shapes/services-v1-shape3.png"
                          }
                          alt="#"
                        />
                      </div>
                      <div className="shape3">
                        <img
                          src={
                            publicUrl +
                            "assets/images/shapes/services-v1-shape4.png"
                          }
                          alt="#"
                        />
                      </div>
                      <div className="services-one__single-img imgwidth">
                        <div
                          className="services-one__single-img-bg"
                          style={{
                            backgroundImage:
                              "url(" +
                              publicUrl +
                              "assets/images/shapes/services-v1-shape1.png)",
                          }}
                        ></div>
                        {/* <div className="overlay-icon">
                          <img src={imaglogo} alt="" />
                        </div> */}

                        <img src={item.image} className="w-100 h-50" alt="#" />
                      </div>

                      <div className="services-one__single-content text-center">
                        <h2>
                          <Link to={`/faq/${item.id}`}>{item.name}</Link>
                        </h2>
                        <p>
                          <div>
                            {item?.sub_categories?.map((sub, index) => {
                              return <p key={index} >{sub.name}</p>;
                            })}
                          </div>{" "}
                          <div className="blog-one__single-content-bottom mt-4 ">
                            <div className="">
                              <Link>
                              
                                  <div className="mt-3">
                                    <Link to={`/faq/${item.id}`} >
                                      {t("Read More ")}{" "}
                                      <span className="icon-right-arrow-1 "></span>
                                    </Link>
                                  </div>
                               
                              </Link>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}
