// CategoryContext.js
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const CategoryContext = createContext();

export const CategoryProvider = ({ children,language }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  const langproduct = language

  useEffect(() => {
    const getCategory = async () => {
      try {
        const { data } = await axios.get(
          "https://landingpage-app-92j29.ondigitalocean.app/e-commerce/8/categories/",
          {
            headers: {
              "Accept-Language": langproduct || "ar" ,
            },
          }
        );
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    getCategory();
  }, [language]);

  return (
    <CategoryContext.Provider value={{ data, loading, error ,langproduct}}>
      {children}
    </CategoryContext.Provider>
  );
};
