import React from "react";

function Map() {
  return (
    <div>
      <section
        style={{
          maxWidth: "100%",
          height: "600px",
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3418.532392252924!2d30.468096976228683!3d31.039275270752206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f66bb75bf2e853%3A0x8760b1c03c5642d2!2sAhmed%20Oraby%2C%20Tamous%2C%20Damanhour%2C%20Beheira%20Governorate!5e0!3m2!1sen!2seg!4v1713770523968!5m2!1sen!2seg"
          width="100%"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
    </div>
  );
}

export default Map;
