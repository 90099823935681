import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import Image from "../../assets/shapes/abouut.jpeg";
import Image2 from "../../assets/shapes/mirorabout.jpeg";
const Breadcrumb = (props) => {
  let heading = props.heading;
  let publicUrl = process.env.PUBLIC_URL + "/";
  let currentPage = props.currentPage ? props.currentPage : heading;
  let Img = props.Img ? props.Img : "page-header-bg.jpg";
  let Img2 = props.Img2 ? props.Img2 : "page-header-shape1.png";
  return (
    <>
      <section class="page-header">
        <div
          class="page-header__bg aboutimagesection"
         /*  style={{
            backgroundImage:
            `url("${Image}")` 
          }} */
        ></div>
        <div class="shape1">
          <img src={publicUrl + "assets/images/shapes/" + Img2 + ""} alt="#" />
        </div>

        <div class="container">
          <div class="page-header__inner">
            <h2>{currentPage}</h2>
            <ul class="thm-breadcrumb">
              <li>
                <Link to={process.env.PUBLIC_URL + `/`}>{t( "Home")}</Link>
              </li>
              <li>
                <span className="pl-1 pr-1"> - </span>
              </li>
              <li>{heading}</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Breadcrumb;
