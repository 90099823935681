import React from "react";
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import ContactForm from "../components/contact/ContactForm";
import ContactMap from "../components/contact/ContactMap";
import FooterOne from "../common/footer/FooterOne";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import IconSocialContact from "../components/contact/iconsocialcontact";
import { NavHashLink } from "react-router-hash-link";

const Contact = () => {
  return (
    <>
      <HeaderThree />
      {/* <Breadcrumb 
                heading="Contact Us"
                currentPage="Contact" 
            /> */}
      <NavHashLink
        smooth
        to="/#contact"
        activeClassName="selected"
      >
        <div className="iconback">
          <FaArrowLeft />
        </div>
      </NavHashLink>

      <ContactForm />

      {/* <ContactMap /> */}
      <FooterOne />
    </>
  );
};

export default Contact;
