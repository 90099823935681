import React from "react";
import { changeLanguage, t } from "i18next";
import { Link } from "react-router-dom";
import imagee from "../../assets/shapes/logomain.png";
import { FaFacebookMessenger, FaLinkedin } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { RiInstagramFill } from "react-icons/ri";

import { NavHashLink } from "react-router-hash-link";

function FooterOne() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <>
      <footer class="footer-one" id="contact">
        <div class="footer-one__bg"></div>
        <div class="shape1 float-bob-y">
          <img
            src={publicUrl + "assets/images/shapes/footer-v1-shape1.png"}
            alt="#"
          />
        </div>
        {/* <div class="shape2 float-bob-y">
            <img
              src={publicUrl + "assets/images/shapes/footer-v1-shape2.png"}
              alt="#"
            />
          </div> */}
        {/* Start Footer */}
        <div class="footer">
          <div class="container">
            <div class="row">
              {/* Start Footer Widget Single */}
              <div
                class="col-xl-5 col-lg-5  wow animated fadeInUp"
                data-wow-delay="0.1s"
              >
                <div class="footer-widget__single">
                  <div class="footer-widget__single-about">
                    <div class="logo-box">
                      <Link to={`/`}>
                        <img src={imagee} className="w-50" alt="#" />
                      </Link>
                    </div>

                    {/* <form class="footer-widget__subscribe-box">
                        <div class="title-box">
                          <h5>{t("sub")}</h5>
                        </div>
                        <div class="footer-widget__subscribe-input-box">
                          <input
                            type="email"
                            placeholder={t("email")}
                            name="email"
                          />
                          <button
                            type="submit"
                            class="footer-widget__subscribe-btn"
                          >
                            <span>{t("Go")}</span>
                          </button>
                        </div>
                      </form> */}
                  </div>
                </div>
              </div>
              {/* End Footer Widget Single */}

              {/* Start Footer One Right */}
              <div class="col-xl-7 col-lg-7">
                <div class="footer-one__right">
                  <div class="row">
                    {/* Start Footer One Right Single */}
                    <div
                      class="col-xl-4 col-lg-4 wow animated fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      <div class="footer-one__right-single ">
                        <div class="footer-one__right-single mb50">
                          <div class="title">
                            <h2>{t("Selectlanguage")}</h2>
                          </div>
                          <div class="footer-one__right-single-links">
                            <ul class="footer-one__right-single-list">
                              <li>
                                <Link
                                  to={process.env.PUBLIC_URL + `/`}
                                  onClick={() => changeLanguage("ar")}
                                >
                                  {t("language1")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={process.env.PUBLIC_URL + `/`}
                                  onClick={() => changeLanguage("en")}
                                >
                                  {" "}
                                  {t("language2")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={process.env.PUBLIC_URL + `/`}
                                  onClick={() => changeLanguage("fr")}
                                >
                                  {t("language3")}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* <div className="select-box   ">
                            <select className="selectmenu wide w-100 p-2 mb-3 px-3 border-0 text-muted pointer ">
                              <option value="volvo" selected="selected">
                                {t("Selectlanguage")}
                              </option>
                              <option>{t("language1")}</option>
                              <option>{t("language2")}</option>
                              <option>{t("language3")}</option>
                            </select>
                          </div> */}
                      </div>
                    </div>
                    {/* End Footer One Right Single */}

                    {/* Start Footer One Right Single */}
                    <div
                      class="col-xl-3 col-lg-3 wow animated fadeInUp"
                      data-wow-delay="0.3s"
                    >
                      <div class="footer-one__right-single mb50">
                        <div class="title">
                          <h2>{t("Links")}</h2>
                        </div>
                        <div class="footer-one__right-single-links">
                          <ul class="footer-one__right-single-list">
                            <li>
                              <Link to={process.env.PUBLIC_URL + `/about`}>
                                {t("About")}
                              </Link>
                            </li>
                            <li>
                              <Link to={process.env.PUBLIC_URL + `/contact`}>
                                {" "}
                                {t("Contact")}
                              </Link>
                            </li>
                            <li>
                              <Link to={process.env.PUBLIC_URL + `/`}>
                                {t("Home")}
                              </Link>
                            </li>
                            <li>
                              <NavHashLink
                                smooth
                                to="/#product"
                                activeClassName="selected"
                                activeStyle={{ color: "red" }}
                                // etc...
                              >
                                {t("product")}
                              </NavHashLink>

                              {/* <Link to={process.env.PUBLIC_URL + `/product`}>
                                  {t("product")}
                                </Link> */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Footer One Right Single */}

                    {/* Start Footer One Right Single */}
                    <div
                      class="col-xl-5 col-lg-5 wow animated fadeInUp"
                      data-wow-delay="0.4s"
                    >
                      <div class="footer-one__right-single">
                        <div class="title">
                          <h2>{t("Contact")}</h2>
                        </div>
                        <div class="footer-one__right-single-contact">
                          <p>
                            {" "}
                            <a href="mailto:info@greenshipcrops.com">
                            info@greenshipcrops.com
                            </a>
                          </p>
                          <p>
                            <Link to="/searchmap">
                              <a>{t("title")}</a>
                            </Link>
                          </p>
                          <div>
                            <p>
                              <a href="tel:+201069272926">+201069272926 </a>
                            </p>
                            <p>
                              <a href="tel:+201111123059">+201111123059 </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Footer One Right Single */}
                  </div>

                  <div
                    class="footer-one__right-bottom wow animated fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <ul class="social-links">
                      <li>
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            `https://twitter.com/Shipgreen5Ship`
                          }
                        >
                          <span class="icon-twitter"></span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            `https://www.facebook.com/profile.php?id=100091618083019&mibextid=hIlR13`
                          }
                        >
                          <span class="icon-facebook"></span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            `https://www.linkedin.com/in/green-ship-02884a2ab/`
                          }
                          className="text-white"
                        >
                          <FaLinkedin />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            `https://www.tiktok.com/@greenship3?lang=en`
                          }
                          className="text-white"
                          style={{ marginRight: "11px" }}
                        >
                          <FaTiktok />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            `https://www.instagram.com/greenship_egypt/?fbclid=IwZXh0bgNhZW0CMTAAAR3ukmz_AHjvR87yGn9YOgBjXtKQZohnIzY16g-XFcJMHYnVL-TJM_AFz0E_aem_AZ8h3D37xa9rWHkhpcYHnUViwx6s0urpxc3IospRsGCi2narfyYVQ9wcQcn_sPkG1htlQIIOatwKx68aU5mrT_dU`
                          }
                          className="text-white"
                          style={{ marginRight: "11px" }}
                        >
                          <RiInstagramFill />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={
                            process.env.PUBLIC_URL + `https://wa.me/01111123059`
                          }
                          className="text-white"
                          style={{ marginRight: "11px" }}
                        >
                          <IoLogoWhatsapp />
                        </Link>
                      </li>
                    </ul>

                    {/* <div class="footer-one__right-bottom-contact">
                        <div class="icon-box">
                          <span class="icon-phone-call"></span>
                        </div>
                        <div class="content-box">
                          <p>{t("CallAnytime")}</p>
                          <h4>
                            <a href="tel:01111123059">{t("number")}</a>
                          </h4>
                        </div>
                      </div> */}
                  </div>
                </div>
              </div>
              {/* End Footer One Right */}
            </div>
          </div>
        </div>

        <div className="footer-one__bottom">
          <div className="container footer-one__bg">
            <div className="bottom-inner footer-one__bg">
              <div className="d-flex justify-content-center  align-items-center">
                <p className="text-white">{t("copyrite")}</p>
                {/* <p>{t("copyrite")}</p> */}
              </div>
              {/* Other elements */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default FooterOne;
