import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion, useAnimation } from "framer-motion";
// import myImage from "../../../src/assets/shapes/slider-v1-img1.jpg";
import { t } from "i18next";
import Slider from "react-slick";
import image3 from "../../../src/assets/shapes/aaaaaaaa23 (1).png";
import i18next from "i18next";

function Navside() {
  const controls = useAnimation();
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    const updateAnimation = () => {
      controls.start({ opacity: 1 - scrollY / 100 });
    };

    window.addEventListener("scroll", handleScroll);
    updateAnimation();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollY, controls]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    pauseOnHover: false,
    once: false,
  };

  return (
    <>
      <section className="slider-three " id="home">
        <div className="slider-three__shape-1 float-bob-y z-index " />

        <div className="slider-three__shape-2 float-bob-x z-index" />

        <div className="slider-three__shape-3 float-bob-y z-index" />

        <div className="slider-three__shape-4 float-bob-y z-index" />

        <div className="container-fluid p-0 m-0">
          <div>
            <div className="slider-three__image w-100 position-relative  ">
              <Slider {...settings}>
                <div style={{ height: "100vh" }}>
                  <div className="image-layerr">
                    <div className="layer px-5 ">
                      <div className=" d-flex content">
                        <div className="layercontent ">
                          <div className="text-center w-75">
                            <img src={image3} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="image-layer">
                  <div className="layer ">
                    <div className=" d-flex content">
                      <motion.div
                        className="layercontent "
                        initial={{ opacity: 0, scale: 0 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 1.5 }}
                      >
                        <h2 className="w-75 fw-bolder fs-1 text-center">
                          {" "}
                          {t("GreenShip")}
                          <br /> {t("ForImport")} <br /> {t("AndExport")}
                        </h2>
                      </motion.div>
                    </div>
                  </div>
                </div>

                <div className="image-layerrr">
                  <div className="layer ">
                    <div className=" d-flex content">
                      <motion.div
                        className="layercontent "
                        initial={{ opacity: 0, scale: 0 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 1.5 }}
                      >
                        <div className="text-center w-75">
                          <img src={image3} />
                        </div>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Navside;
