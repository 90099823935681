import React, { useEffect } from "react";
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import FaqTwo from "../components/faq/FaqTwo";
import FooterOne from "../common/footer/FooterOne";
import { t } from "i18next";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

const Faq = () => {
  useEffect(() => {
    window.scroll(800, 800);
  }, []);

  const { id } = useParams();
  console.log("id", id);

  return (
    <>
      {/* commit */}
      <HeaderThree />
      <Breadcrumb heading={t("product")} currentPage={t("product")} />
      <div className="cursor-pointer  ">
        <NavHashLink smooth to="/#product" activeClassName="selected">
          <div className="iconback">
            <FaArrowLeft />
          </div>
        </NavHashLink>
      </div>
      <FaqTwo id={id} />
      <FooterOne />
    </>
  );
};

export default Faq;
