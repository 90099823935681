import React from 'react';
import {Link} from "react-router-dom";
import  Image  from '../../assets/shapes/aaaaaaaa23 (1).png';
export default class LogoOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <div className="logo-box-one">
                    <Link to={process.env.PUBLIC_URL + `/`}>
                        <img style={{width:'200px'}} src={Image} alt="Awesome Logo" />
                    </Link>
                </div>
            </>
        )
    }
}