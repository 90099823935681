import React from "react";
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import FooterOne from "../common/footer/FooterOne";
import { t } from "i18next";
import AboutFive from "../components/about/aboutfive";

import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { NavHashLink } from "react-router-hash-link";

const About = () => {
  return (
    <>
      <HeaderThree />
      <Breadcrumb heading={t("aboutUs")} currentPage={t("aboutUs")} />
      <div className="my-5">
        <AboutFive />
      </div>
      <div className="cursor-pointer  ">
        <NavHashLink smooth to="/#about" activeClassName="selected">
          <div className="iconback">
            <FaArrowLeft />
          </div>
        </NavHashLink>
      </div>

      <FooterOne />
    </>
  );
};

export default About;
