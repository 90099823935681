import { t } from 'i18next';

function datanav() {
 const menuData = [
    {
      title: t("Home"),
      path: "/",
    },
    {
      title: t("product") ,
      path: "#product",
    },
  
    {
      title: t("About") ,
      path: "#about",
    },
  
    // {
    //   title: "services",
    //   path: "#servic",
    // },
   
    {
      title: t("Contact"),
      path: "#contact",
    },
  ];
  return menuData
}

export default datanav