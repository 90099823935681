import React from 'react';
import HeaderThree from '../common/header/HeaderThree';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';
import AboutOne from '../components/about/AboutOne';
import { t } from 'i18next';
import Map from '../components/map/map';

const SearchMap = () => {
    return (
        <>
            <HeaderThree />
            <Breadcrumb 
                heading={t("Our Location")}
                currentPage={t("Our Location")}
            />
           <div className='my-5'>
           <Map />
           </div>
           
            <FooterOne />
        </>
    )
}

export default SearchMap;