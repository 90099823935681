import React from 'react';
import { Link } from 'react-router-dom';
// import image1 from '../../assets/shapes/blog-v1-img1 (1).jpg'
// import Image2 from '../../assets/shapes/blog-v1-img2 (1).jpg'
// import Image3 from '../../assets/shapes/blog-v1-img3 (1).jpg'
import { t } from 'i18next';
export default class BlogOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="blog-one">
                    <div className="container">
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>{t("Recent Posts")}</h6>
                                <span className="right"></span>
                            </div>
                            <h2 className="sec-title__title">{t("Latest News From Blog")}</h2>
                        </div>
                        <div className="row">
                            {/* Start Blog One Single */}
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                                <div className="blog-one__single">
                                    <div className="blog-one__single-content">
                                        {/* <ul className="meta-box clearfix">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-calendar"></span>
                                                </div>
                                                <div className="text">
                                                    <p><a href="#"> January 2, 2023 </a></p>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="icon">
                                                    <span className="icon-user"></span>
                                                </div>
                                                <div className="text">
                                                    <p><a href="#"> by Admin</a></p>
                                                </div>
                                            </li>
                                        </ul> */}

                                        <div className="blog-one__single-content-inner">
                                            <h2><Link to={process.env.PUBLIC_URL + `/blog-details`}>{t("pomegranate")}</Link></h2>
                                            <p>{t("pomegranateParagraph")}</p>
                                        </div>


                                        <div className="blog-one__single-content-bottom clearfix">
                                            <ul className="clearfix">
                                                {/* <li>
                                                    <div className="comment-box">
                                                        <a href="#"> <span className="icon-conversation"></span> 3 comments</a>
                                                    </div>
                                                </li> */}

                                                <li>
                                                    <div className="btn-box">
                                                        <Link to={process.env.PUBLIC_URL + `/blog-details`}>{t("Read More ")}<span className="icon-right-arrow-1"></span></Link>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>

                                    {/* <div className="blog-one__single-img">
                                        <img src={Image3} alt="#" />
                                    </div> */}
                                </div>
                            </div>
                            {/* End Blog One Single */}

                            {/* Start Blog One Single */}
                            <div className="col-xl-4 col-lg-4 wow fadeInDown" data-wow-delay=".3s">
                                <div className="blog-one__single">
                                    <div className="blog-one__single-content">
                                        {/* <ul className="meta-box clearfix">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-calendar"></span>
                                                </div>
                                                <div className="text">
                                                    <p><a href="#"> January 2, 2023 </a></p>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="icon">
                                                    <span className="icon-user"></span>
                                                </div>
                                                <div className="text">
                                                    <p><a href="#"> by Admin</a></p>
                                                </div>
                                            </li>
                                        </ul> */}

                                        <div className="blog-one__single-content-inner">
                                            <h2><Link to={process.env.PUBLIC_URL + `/blog-details`}>  {t("Lemon")}</Link></h2>
                                            <p>{t("LemonParagraph")}</p>
                                        </div>


                                        <div className="blog-one__single-content-bottom clearfix">
                                            <ul className="clearfix">
                                                {/* <li>
                                                    <div className="comment-box">
                                                        <a href="#"> <span className="icon-conversation"></span> 3 comments</a>
                                                    </div>
                                                </li> */}

                                                <li>
                                                    <div className="btn-box">
                                                        <Link to={process.env.PUBLIC_URL + `/blog-details`}>{t("Read More ")} <span className="icon-right-arrow-1"></span></Link>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>

                                    {/* <div className="blog-one__single-img">
                                        <img src={Image2} alt="#" />
                                    </div> */}
                                </div>
                            </div>
                            {/* End Blog One Single */}

                            {/* Start Blog One Single */}
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                                <div className="blog-one__single">
                                    <div className="blog-one__single-content">
                                        {/* <ul className="meta-box clearfix">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-calendar"></span>
                                                </div>
                                                <div className="text">
                                                    <p><a href="#"> January 2, 2023 </a></p>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="icon">
                                                    <span className="icon-user"></span>
                                                </div>
                                                <div className="text">
                                                    <p><a href="#"> by Admin</a></p>
                                                </div>
                                            </li>
                                        </ul> */}

                                        <div className="blog-one__single-content-inner">
                                            <h2><Link to={process.env.PUBLIC_URL + `/blog-details`}>{t("mango")}</Link></h2>
                                            <p>{t("mangoParagraph")}</p>
                                        </div>


                                        <div className="blog-one__single-content-bottom clearfix">
                                            <ul className="clearfix">
                                                {/* <li>
                                                    <div className="comment-box">
                                                        <a href="#"> <span className="icon-conversation"></span> 3 comments</a>
                                                    </div>
                                                </li> */}

                                                <li>
                                                    <div className="btn-box">
                                                        <Link to={process.env.PUBLIC_URL + `/blog-details`}>{t("Read More ")} <span className="icon-right-arrow-1"></span></Link>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>

                                    {/* <div className="blog-one__single-img">
                                        <img src={image1} alt="#" />
                                    </div> */}
                                </div>
                            </div>
                            {/* End Blog One Single */}

                        </div>
                    </div>
                </section>
            </>
        )
    }
}